.header {
  padding-left: 0 !important;
  position: fixed;
  z-index: 1;
  width: 100%;
  box-shadow: 0px 10px 20px #fff, 0px 5px 5px #fff;
  .ant-row,
  .ant-col {
    height: inherit;
  }
  .logo {
    // height: 70%;
    padding: 10px;
    width: min-content;
    margin: 0 16px;
    float: left;
    svg {
      height: 100%;
      width: auto;
      fill: #2056ce;
    }
  }
  .dropdown-user {
    .anticon-user {
      margin-right: 10px;
    }
    .anticon-down {
      margin-left: 10px;
    }
  }
}
