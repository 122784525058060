/* ROBOTO */
@font-face {
  font-family: "Roboto", sans-serif;
  src: url("Roboto/Roboto-Black.woff2") format("woff2"),
    url("Roboto/Roboto-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("Roboto/Roboto-Bold.woff2") format("woff2"),
    url("Roboto/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("Roboto/Roboto-ThinItalic.woff2") format("woff2"),
    url("Roboto/Roboto-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("Roboto/Roboto-Italic.woff2") format("woff2"),
    url("Roboto/Roboto-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("Roboto/Roboto-Regular.woff2") format("woff2"),
    url("Roboto/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("Roboto/Roboto-MediumItalic.woff2") format("woff2"),
    url("Roboto/Roboto-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("Roboto/Roboto-BlackItalic.woff2") format("woff2"),
    url("Roboto/Roboto-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("Roboto/Roboto-Light.woff2") format("woff2"),
    url("Roboto/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("Roboto/Roboto-LightItalic.woff2") format("woff2"),
    url("Roboto/Roboto-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("Roboto/Roboto-BoldItalic.woff2") format("woff2"),
    url("Roboto/Roboto-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("Roboto/Roboto-Medium.woff2") format("woff2"),
    url("Roboto/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("Roboto/Roboto-Thin.woff2") format("woff2"),
    url("Roboto/Roboto-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

/* LATO */
@font-face {
  font-family: "Lato";
  src: url("Lato/Lato-Black.eot"); /* IE9 Compat Modes */
  src: url("Lato/Lato-Black.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Lato/Lato-Black.woff2") format("woff2"),
    /* Modern Browsers */ url("Lato/Lato-Black.woff") format("woff"),
    /* Modern Browsers */ url("Lato/Lato-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: bolder;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Lato";
  src: url("Lato/Lato-BlackItalic.eot"); /* IE9 Compat Modes */
  src: url("Lato/Lato-BlackItalic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Lato/Lato-BlackItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("Lato/Lato-BlackItalic.woff") format("woff"),
    /* Modern Browsers */ url("Lato/Lato-BlackItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: bolder;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Lato";
  src: url("Lato/Lato-Bold.eot"); /* IE9 Compat Modes */
  src: url("Lato/Lato-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Lato/Lato-Bold.woff2") format("woff2"),
    /* Modern Browsers */ url("Lato/Lato-Bold.woff") format("woff"),
    /* Modern Browsers */ url("Lato/Lato-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Lato";
  src: url("Lato/Lato-BoldItalic.eot"); /* IE9 Compat Modes */
  src: url("Lato/Lato-BoldItalic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Lato/Lato-BoldItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("Lato/Lato-BoldItalic.woff") format("woff"),
    /* Modern Browsers */ url("Lato/Lato-BoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Lato";
  src: url("Lato/Lato-Italic.eot"); /* IE9 Compat Modes */
  src: url("Lato/Lato-Italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Lato/Lato-Italic.woff2") format("woff2"),
    /* Modern Browsers */ url("Lato/Lato-Italic.woff") format("woff"),
    /* Modern Browsers */ url("Lato/Lato-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Lato";
  src: url("Lato/Lato-Light.eot"); /* IE9 Compat Modes */
  src: url("Lato/Lato-Light.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Lato/Lato-Light.woff2") format("woff2"),
    /* Modern Browsers */ url("Lato/Lato-Light.woff") format("woff"),
    /* Modern Browsers */ url("Lato/Lato-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: lighter;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Lato";
  src: url("Lato/Lato-LightItalic.eot"); /* IE9 Compat Modes */
  src: url("Lato/Lato-LightItalic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Lato/Lato-LightItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("Lato/Lato-LightItalic.woff") format("woff"),
    /* Modern Browsers */ url("Lato/Lato-LightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: lighter;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Lato";
  src: url("Lato/Lato-Regular.eot"); /* IE9 Compat Modes */
  src: url("Lato/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Lato/Lato-Regular.woff2") format("woff2"),
    /* Modern Browsers */ url("Lato/Lato-Regular.woff") format("woff"),
    /* Modern Browsers */ url("Lato/Lato-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "LatoThin";
  src: url("Lato/Lato-Thin.eot"); /* IE9 Compat Modes */
  src: url("Lato/Lato-Thin.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Lato/Lato-Thin.woff2") format("woff2"),
    /* Modern Browsers */ url("Lato/Lato-Thin.woff") format("woff"),
    /* Modern Browsers */ url("Lato/Lato-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "LatoThin";
  src: url("Lato/Lato-ThinItalic.eot"); /* IE9 Compat Modes */
  src: url("Lato/Lato-ThinItalic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Lato/Lato-ThinItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("Lato/Lato-ThinItalic.woff") format("woff"),
    /* Modern Browsers */ url("Lato/Lato-ThinItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

:root {
  --size-12: 0.75em;
  --size-14: 0.875em;
  --size-16: 1em;
  --size-18: 1.1em;
  --size-20: 1.25em;
  --size-22: 1.375em;
  --size-24: 1.5em;
  --size-28: 1.75em;
  --size-30: 1.875em;
  --size-32: 2em;
  --size-38: 2.4em;
  --size-42: 2.625em;
  --size-48: 3em;
  --size-52: 3.25em;
  --size-60: 3.75em;
  --size-65: 4.5em;
  --size-70: 5em;

  --text-primary-font: "Roboto";
  --text-secondary-font: "Roboto";

  --btn-transition-ms: 200ms;
  --btn-transition-ease: ease;

  --breakpoint-small: 767px;
  --breakpoint-med: 1023px;
  --breakpoint-large: 1547px;
  --breakpoint-xlarge: 1548px;
}

body {
  font-family: "Lato", sans-serif !important;
}
