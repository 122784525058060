@import "./assets/styles/animations";
@import "./assets/styles/utils";
@import "./assets/styles/antd-overrides";
@import "./assets/fonts/stylesheet";
@import "./../node_modules/simplebar/dist/simplebar.min";

html,
body {
  min-height: 100vh;
  text-rendering: geometricPrecision;
}

#root {
  height: 100%;
}

h1,
h2,
h3 {
  font-family: "Bodoni 72", serif;
}
