$primaryColor: #2056ce;
$primaryBlue: #2056ce;
$fadedColor: #eff5ff;
$halfFadedColor: #d9daf5;
$error: #f76565;
$textBlack: #141414;

.detail-pane {
  padding: 10px;
  margin-bottom: 40px;
  border-radius: 10px;
  .toolbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
    padding: 0 10px 10px 10px;
    h2 {
      margin: 0;
      font-size: 24px;
      color: $primaryBlue;
    }
  }
  .ant-descriptions-item-content {
    width: 100%;
  }
  p {
    margin: 0;
    color: $textBlack;
  }
  .ant-input {
    &[disabled] {
      cursor: default;
      border-color: transparent !important;
      // border-left-width: 0px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      background-color: var(--background);
    }
  }
  textarea {
    &.ant-input {
      resize: none;
    }
  }
  .ant-picker {
    &.ant-picker-disabled {
      background-color: var(--background);
      cursor: default;
      padding-left: 0px !important;
      padding-right: 0px !important;
      border-color: transparent;
      .ant-picker-suffix {
        opacity: 0;
      }
    }
  }
  .ant-select {
    .ant-select-selection-search {
      transition: all 150ms ease;
    }
    &.ant-select-disabled {
      .ant-select-selector {
        cursor: default;
        border-color: transparent !important;
        background-color: var(--background);
        // border-left-width: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        .ant-select-selection-search {
          left: 0;
          right: 0;
        }
      }
      .ant-select-arrow {
        opacity: 0;
      }
    }
  }
  .tag-bg {
    margin-bottom: 10px;
    &.filled {
      background: $fadedColor;
      border-color: $primaryColor;
    }
    a {
      text-decoration-line: underline;
      text-decoration-style: dotted;
      text-decoration-color: transparentize($primaryColor, 0.5);
      color: transparentize($primaryColor, 0.1);
      transition: all 150ms ease;
      &:hover {
        text-decoration-style: solid !important;
        text-decoration-color: $primaryColor !important;
        color: $primaryColor;
      }
    }
  }
  .input-error {
    border-color: $error;
  }
  .creation-box {
    transition: all 200ms ease;
    overflow: hidden;
    &.open {
      max-height: 100000px;
    }
    &.closed {
      max-height: 0px;
    }
  }
  .form-error-led {
    padding: 5px 15px;
    background: #efefef;
    border-radius: 20px;
    margin-left: 10px;
    font-size: 0.5em;
    font-family: "Lato", sans-serif;
    .led {
      margin-right: 10px;
    }
  }
  .ant-upload {
    border: none;
    background-color: transparent;
    &.ant-upload-disabled {
      cursor: pointer;
    }
  }
}

.ant-select-multiple {
  .ant-select-selection-item {
    background: $fadedColor !important;
    border-color: $halfFadedColor !important;
    .ant-select-selection-item-content {
      color: initial !important;
      font-size: 12px;
    }
  }
}

/* Masonry grid */
.masonry {
  column-count: 4;
  column-gap: 1em;
  .ant-col {
    display: inline-block;
    margin: 0 0 1em;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 10px;
  }
}
