@import "src/assets/styles/colors.scss";

.ant-select-tree-treenode-disabled {
  .ant-select-tree-title {
    text-decoration: line-through !important;
  }
}

.ant-select-tree
  .ant-select-tree-treenode-disabled
  .ant-select-tree-node-content-wrapper {
  text-decoration: line-through !important;
}

.ant-tree .ant-tree-list .ant-tree-list-holder-inner {
  background-color: var(--background);
}
