:root {
  --gold: #ffc72c;
  --gold-lighter: #ffd460;
  --black: rgba(0, 0, 0, 0.65);
  --total-black: rgba(0, 0, 0);
  --black-lighter: rgba(0, 0, 0, 0.38);
  --text-black: #141414;
  --grey-lighter: #f0f2f5;
  --light-bg: #fff;
  --lighter-grey-bg: #f2f2f2;
  --secondary-accent: #ff2850;
  --light-grey: #d6d6d6;
  --light-grey-bg: #999999;
  --light-grey-muted-bg: #efefef;
  --light-grey-highlighted-bg: #e2e2e2;
  --light-grey-text: #666666;
  --separator-grey: #d6d6d6;
  --card-background-black: #1a1a1a;
  --border-color-light: #b2b2b2;
  --border-input-upload: #d5d5d5;
  --red-alert: #e24949;
  --background: #fbfbfe;
}
