.led {
  border-radius: 100%;
  display: inline-block;
  margin-top: -1px;
  margin-left: -1px;

  &.on {
    background-color: #bcf2ff;
    border: 1px solid #9bdfff;
    box-shadow: 0px 0px 2px 1px #ffffff, 0px 0px 8px 1px #7ce0ff,
      inset -0.5px -0.5px 2px 2px #59d3f8;
    &.success {
      background-color: #e6ffbc;
      border: 1px solid #d9ff9b;
      box-shadow: 0px 0px 2px 1px #ffffff, 0px 0px 8px 1px #cdff7c,
        inset -0.5px -0.5px 2px 2px #bbf859;
    }
    &.warning {
      background-color: #fff3bf;
      border: 1px solid #ffeaa3;
      box-shadow: 0px 0px 2px 1px #ffffff, 0px 0px 8px 1px #ffeea3,
        inset -0.5px -0.5px 2px 2px #f8c059;
    }
    &.error {
      background-color: #ffbfbf;
      border: 1px solid #ffa3a3;
      box-shadow: 0px 0px 2px 1px #ffffff, 0px 0px 8px 1px #ffa3a3,
        inset -0.5px -0.5px 2px 2px #f85959;
    }
  }
  &.off {
    background-color: #ffffff33;
    border: 1px solid #eeeeee;
    box-shadow: 1px 1px 4px -2px #cacaca, 1.5px 2px 4px -2px #d1d1d1,
      2px 4px 4px -2px #d6d6d6, inset -0.5px -0.5px 2px 2px #eeeeee;
  }
}
