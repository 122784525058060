.placeholders {
  &.placeholder-title {
    background-image: url(./titleplaceholder.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  &.placeholder-paragraph {
    background-image: url(./textplaceholder.svg);
    background-repeat: repeat;
    background-size: contain;
  }
}
